import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";
import Footer from "../../components/Footer";
import Booking from "../../components/Booking";

const Reviews = () => {
  return (
    <>
      <section id="reviews">
        <h2
className="headingrev"
        >
          Reviews
        </h2>

        <div className="container">
          <div className="row">
            <div
              className="col-md-6 align-self-center wow slideInLeft"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <h1>What Our Happy Clients Have To Say</h1>
              <p>
                At Consumer Protection Networks, our clients are at the core of
                our mission. We prioritize their needs, concerns, and
                satisfaction above all else. All reviews posted on this site are
                the exclusive property of the reviewer and/or the third-party
                platform on which the review was submitted. <br />
                Here are endorsement videos and Text from respected individuals
                who recognize our commitment to excellence:
              </p>
            </div>
            <div
              className="col-md-6 wow slideInRight"
              data-wow-duration="0.75s"
              data-wow-delay="0s"
            >
              <img
                src="images/Reviews.png"
                alt="reviews"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section id="video">
        <div className="video-wrapper">
          <h1>
            Here are endorsement videos from respected individuals who recognize
            our commitment to excellence
          </h1>
          <div className="video-container">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/UUEebOm04Nk?si=BI1uMoNIlTE9jR0_"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/QBvGUNacI28?si=Rjw_AUbzuX-Enx6B"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/iKu933-nXkY?si=U7W8tZCsJG91syuL"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/ZOnKbQJXKv0?si=KzcSK7i9e_5W8yHq"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/8jIPE2ezik8?si=cAu24UpNKw9L67o0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>
      <section className="testimonial text-center">
        <h2>
          And here's what some of our valued clients have said about their
          experience with us:
        </h2>
        <div className="container wow slideInRight">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            autoplay={{ delay: 5000 }}
            speed={2000}
            className="carousel"
            modules={[Navigation]}
          >
            <SwiperSlide>
              <div className="testimonial4_slide">
                <img
                  src="images/Man-4.jpg"
                  alt="Testimonial"
                  className="img-circle img-responsive"
                />
                <p>
                  This company worked with my parents to help them out of a few
                  timeshares. The process took about 4-5 months and the
                  communication was thorough throughout the process. Thank you
                </p>
                <h4>Danny C</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial4_slide">
                <img
                  src="images/Man-4.jpg"
                  alt="Testimonial"
                  className="img-circle img-responsive"
                />
                <p>
                  After working with Consumer Protection Networks I couldn’t be
                  happier and more relieved! We had owned for several years and
                  have been trying to exit out of our ownership since Covid. We
                  even worked with the ovations department and was ultimately
                  denied of our request to give our points back! After hearing
                  an informational meeting with a Consumer Protection Networks
                  team, we decided to allow them to help us. After about 6.5
                  months I am proud to say we are no longer timeshare owners!
                </p>
                <h4>Anthony E</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial4_slide">
                <img
                  src="images/Man-4.jpg"
                  alt="Testimonial"
                  className="img-circle img-responsive"
                />
                <p>
                  I recently attended a timeshare meeting in San Diego held by
                  Consumer Protection Networks. After arriving I had a
                  consultant Gary assigned to me. He explained some laws had
                  changed regarding timeshare but most likely didn’t apply since
                  my timeshare was in Mexico and I only paid when I used it.
                  After the presentation Gary retuned, asked if I had any
                  questions and thanked me for coming in. He also advised me
                  since my property was in Mexico and lease was up in like 2
                  years that I should just finish the lease, since I only paid
                  when I used it, it didn’t cost me any money when it goes away.
                  I was very relieved to hear that! I’ve been to a couple of
                  these meetings with other companies and they always want to
                  sell me more timeshare or have me pay them to sell mine.
                </p>
                <h4>Edward M</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial4_slide">
                <img
                  src="images/Man-4.jpg"
                  alt="Testimonial"
                  className="img-circle img-responsive"
                />
                <p>
                  Met with Jay at a meeting back in August I have been to
                  several meetings with a lot of company’s and they all told me
                  because I had upgraded they couldn’t help me out of my
                  timeshare but I spent several hours with Jay and he helped me
                  set up getting out of my timeshare and my loan on it both I
                  truly feel god brought him to me , thank you for the help and
                  god bless your new granddaughter!!
                </p>
                <h4>Kelly R</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial4_slide">
                <img
                  src="images/Man-4.jpg"
                  alt="Testimonial"
                  className="img-circle img-responsive"
                />
                <p>
                  We are writing this review with immense gratitude, following
                  our recent termination of our Diamond timeshare. Consumer
                  Protection Networks guided us with all the knowledge we needed
                  to initialize the process, nut like anything else it also
                  required our trust. We were told as long as we sent in all our
                  documents, the process would be completed in 12 months or
                  less, seamlessly. We are now sitting here with a smile on our
                  face and want to say thank you to the entire team only six
                  months and three weeks later to completion! We are now free of
                  our forever our maintenance fees, and furthermore, our kids
                  will not be forced to pay them in the future either. Thank you
                  again to the entire team, and especially my consultant that
                  guided us the entire way. We realize there are a lot of scams
                  out there and empty promises, that can have us all a bit jaded
                  at times. My husband and and I are beyond grateful we trusted
                  in Consumer Protection Networks as they fulfilled our goals
                  beyond our wishes!
                </p>
                <h4>Ginny and Tom S</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial4_slide">
                <img
                  src="images/Man-4.jpg"
                  alt="Testimonial"
                  className="img-circle img-responsive"
                />
                <p>
                  My husband and I attended an informational event about 4
                  months ago, hosted by Consumer Protection Networks regarding
                  our timeshare we purchased several years ago. During the event
                  our speaker Fenix was very informative and advised of several
                  things that have changed for timeshares. Gary was our
                  meditator assigned to us and continued to answer our questions
                  after Fenix had completed the seminar. For the first time we
                  feel like we got our questions answered about what we
                  purchased and how to eliminate it. We have owned this and have
                  used it very little due to no availability! We decided to have
                  Consumer Protection Networks assist us in exiting our
                  timeshare, I waited to write this till after they completed
                  our exit. I am happy to say we are free and clear of our
                  timeshare! Thank you to everyone at Consumer Protection
                  Networks!
                </p>
                <h4>Emily V</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="testimonial4_slide">
                <img
                  src="Man-4.jpg"
                  alt="Testimonial"
                  className="img-circle img-responsive"
                />
                <p>
                  Excellent job! relieved my debt! very professional and great
                  customer service and follow up.
                </p>
                <h4>Richard B</h4>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
      <Booking />

      <Footer />
    </>
  );
};

export default Reviews;
