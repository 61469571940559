import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAqMAFqIJoQpbFziUL3LwsArICSR90CN14",
  authDomain: "consumer-protection-networks.firebaseapp.com",
  projectId: "consumer-protection-networks",
  storageBucket: "consumer-protection-networks.appspot.com",
  messagingSenderId: "1090726386619",
  appId: "1:1090726386619:web:719f490e3ef2e29cde829e",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };
export default firebaseConfig;
