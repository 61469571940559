import React from "react";
import Footer from "../../components/Footer";
import Booking from "../../components/Booking";
import { Link } from "react-router-dom";
import "./home.css";

const Home = () => {
  return (
    <>
      <section id="home">
        <div className="home-overlay"></div>
        <div className="home-content">
          <header className="home-header wow slideInDown">
            WELCOME TO CONSUMER <br /> PROTECTION NETWORKS
          </header>
          <h4 className="home-subtitle wow animate__animated animate__zoomIn">
            Cancel Your Timeshare
          </h4>
          <h5 className="wow animate__animated animate__zoomIn">
            Your #1 Timeshare Exit Company
          </h5>
          <button
            className="homeee animate__animated animate__slideInUp wow d-block mx-auto"
            id="bookACallBtn"
            onClick={() =>
              window.open(
                "https://meetings.hubspot.com/release-department/client-update-calls-?uuid=3ed00896-70c1-41c5-9703-8024ee8e575b",
                "_blank"
              )
            }
          >
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="svg-icon"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z"
                  stroke="#ffffff"
                  strokeWidth="1.5"
                ></path>
                <path
                  d="M7 4V2.5"
                  stroke="#ffffff"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M17 4V2.5"
                  stroke="#ffffff"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M2.5 9H21.5"
                  stroke="#ffffff"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></path>
                <path
                  d="M18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M18 13C18 13.5523 17.5523 14 17 14C16.4477 14 16 13.5523 16 13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13Z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17Z"
                  fill="#ffffff"
                ></path>
                <path
                  d="M8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13Z"
                  fill="#ffffff"
                ></path>
              </g>
            </svg>
            <span>Schedule An Update Call</span>
          </button>
        </div>
      </section>

      <section id="Aboutus" className="container">
        <header className="about-header">About Us</header>
        <div className="row">
          <div className="col-md-6 align-self-center wow slideInLeft vertical-col">
            <h1>Timeshare Exit</h1>
            <p>
              Welcome to Consumer Protection Networks, where we've assisted
              countless clients in liberating themselves from the burdens of
              timeshare contracts. <br /> <br />
              Our premier service includes comprehensive title transfer
              solutions, effectively removing our clients' timeshare properties
              from their names legally and hassle-free. <br /> <br />
              Additionally, we specialize in timeshare mortgage and maintenance
              fees cancellation, providing a path to financial freedom for our
              valued clients. Join the ranks of satisfied customers who have
              found relief with Consumer Protection Networks.
            </p>
            <Link to="/About" className="btn2 btn-link2">
              <span className="icon2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-info-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm0 12a1 1 0 0 0 1-1 1 1 0 0 0-2 0 1 1 0 0 0 1 1zm-.004-3a1 1 0 0 0 1-1V5a1 1 0 1 0-2 0v3a1 1 0 0 0 1 1z"
                  />
                </svg>
              </span>
              <span className="text2">More Info</span>
            </Link>
          </div>
          <div className="col-md-6 wow slideInRight vertical-col">
            <img
              src="images/About us.png"
              alt="About Us"
              className="about-image"
            />
          </div>
        </div>
      </section>

      <section className="services-1">
        <header className="services-header">Our Services</header>
        <section className="box-section2">
          <div
            className="box-example2 animate__animated animate__zoomIn wow"
            data-wow-delay="0s"
          >
            <div className="box-content2">
              <h2>Mortgage Cancellation</h2>
              <img
                src="images/Mortgage Cancellation.png"
                alt="Mortgage Cancellation"
                className="img-fluid"
              />
              <p>
                Timeshare companies are notorious for using high-pressure sales
                tactics and giving out misleading information and false
                promises.
              </p>
            </div>
          </div>
          <div
            className="box-example2 animate__animated animate__zoomIn wow"
            data-wow-delay="0.25s"
          >
            <div className="box-content2">
              <h2>Title Transfer Services</h2>
              <img
                src="images/Title transfer.png"
                alt="Title Transfer Services"
                className="img-fluid"
              />
              <p>
                We will help you legally transfer your timeshare property out of
                your name. Once completed, you will be free from all financial
                liability.
              </p>
            </div>
          </div>
          <div
            className="box-example2 animate__animated animate__zoomIn wow"
            data-wow-delay="0.5s"
          >
            <div className="box-content2">
              <h2>Maintenance Fees Cancellation</h2>
              <img
                src="images/Maintenance Fees Cancellation.png"
                alt="Maintenance Fees Cancellation"
                className="img-fluid"
              />
              <p>
                Cancelling your Timeshare ownership and maintenance fees can
                often be more simple than you think. Most Timeshare contracts
                are not worth the paper they're written on.
              </p>
            </div>
          </div>
        </section>
      </section>

      <section className="services-2">
        <header className="header-text">What Makes Us Different!</header>
        <div className="box-section">
          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="0s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/bqkijzun.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Happy clients</h1>
              <p>
                Hundreds of happy customer cannot be wrong. Customer reviews
                speak volumes for a business and the services they perform. We
                strive to maintain the highest level of service throughout your
                timeshare exit process with us.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="0.25s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/qvyppzqz.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>24/7 support</h1>
              <p>
                24/7 support means customers can get help and find answers to
                questions as soon as they come up—24/7 and in real-time.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="0.5s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/kndkiwmf.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Experienced Team</h1>
              <p>
                We recognize that our success lies in the hands of our staff. We
                acknowledge this and are privileged to have such a diverse group
                of professionals that are friendly, courteous, and exceptional
                at what they do.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="0.75s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/nchegqgo.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Proven Exit Strategy</h1>
              <p>
                We have demonstrated to be a formidable timeshare exit partner.
                Our experience and timeshare exit process has helped many
                timeshare owners put an end to their unwanted timeshare contract
                liabilities.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="1s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/vistbkts.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Secure Agent Information</h1>
              <p>
                Means sensitive data agents handle, like customer details or
                company secrets. To keep it safe, organizations use measures
                such as access controls, encryption, and training for agents.
                Monitoring and auditing help detect and address security issues.
              </p>
            </div>
          </div>

          <div
            className="box-example wow animate__animated animate__zoomIn"
            data-wow-delay="1.25s"
          >
            <div className="box-content">
              <lord-icon
                src="https://cdn.lordicon.com/wyqtxzeh.json"
                trigger="hover"
                stroke="bold"
                colors="primary:#706464,secondary:#000000"
                style={{ width: "100px", height: "100px" }}
              />
              <h1>Money-back Guarantee</h1>
              <p>
                With decades of timeshare exit experience among CPN's leadership
                group, we are confident in our ability to deliver. We provide
                owners with a full money-back guarantee if our services are not
                completed within the agreed-upon time frame. This assurance
                underscores our commitment to your satisfaction and gives you
                peace of mind when choosing our services.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Booking />

      <Footer />
    </>
  );
};

export default Home;
