import React from 'react';

const Social = () => {

  return (
<div className='social'>
<div class="message">
<div class="texts">
  <div class="text text-front">coming soon</div>
  <div class="text text-back">coming never</div>
</div>
</div>
</div>
  ); 
};

export default Social;


