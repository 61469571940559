import React from "react";
import Footer from "../../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div id="privacy" style={{ padding: "20px" }}>
        <header
          style={{
            color: "#0c0c0d",
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "bold",
            marginBottom: "100px",
            marginTop: "100px",
          }}
        >
          Privacy Policy
        </header>

        <p>
          At Consumer Protection Networks, we prioritize the security and
          confidentiality of your information. We do not share any personal data
          provided to us with third parties. Your privacy matters to us, and we
          are committed to safeguarding it at all times.
        </p>
        <h2>Information Collection:</h2>
        <p>
          We may collect personal information from you when you visit our
          website, register for an account, make a purchase, or subscribe to our
          services. This information may include your name, email address, phone
          number, billing address, and payment details. Data may be collected
          through forms, cookies, or other tracking technologies.
        </p>
        <h2>How We Use Your Information:</h2>
        <p>
          We use the information we collect to provide and improve our services,
          process transactions, communicate with you, and personalize your
          experience. We may also use your information to send you promotional
          offers, updates, and other marketing communications. You can opt out
          of receiving marketing communications at any time by following the
          instructions provided in the messages or contacting us directly.
        </p>
        <h2>Data Security:</h2>
        <p>
          We implement a variety of security measures to protect your personal
          information and maintain its confidentiality. We use industry-standard
          encryption protocols to secure data transmission and storage. We also
          adhere to relevant data protection regulations and standards.
        </p>
        <h2>SMS Usage and Consent:</h2>
        <p>
          By providing your mobile phone number and opting in to receive SMS
          notifications or communications from us, you consent to receive text
          messages from Consumer Protection Networks. We may use SMS to send you
          order updates, account notifications, promotional offers, and other
          relevant information related to our services.
        </p>
        <h2>Obtaining Consent:</h2>
        <p>
          We obtain consent from users before sending any marketing messages via
          SMS. You may opt out of receiving SMS communications at any time by
          following the instructions provided in the messages or contacting us
          directly at{" "}
          <a href="mailto:clientservices@consumerprotectionnetworks.com">
            clientservices@consumerprotectionnetworks.com
          </a>
          .
        </p>
        <h2>Compliance with Carrier Policies:</h2>
        <p>
          We strictly adhere to carrier SMS policies and guidelines to ensure
          compliance with industry regulations and standards. Our SMS practices
          are designed to provide users with a secure and reliable messaging
          experience.
        </p>
        <h2>Updates to Privacy Policy:</h2>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be reflected on this page. We will also notify
          you of significant changes through email or by posting a notice on our
          website. We encourage you to review this Privacy Policy periodically
          for updates. By using our website and services, you acknowledge that
          you have read and agree to the terms of this Privacy Policy. If you
          have any questions or concerns about our Privacy Policy or SMS
          practices, please contact us at{" "}
          <a href="mailto:clientservices@consumerprotectionnetworks.com">
            clientservices@consumerprotectionnetworks.com
          </a>
          .
        </p>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
