import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../services/firebase";
import { doc, onSnapshot } from "firebase/firestore";

function TablePage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [consultations, setConsultations] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        subscribeToConsultations();
      } else {
        navigate("/SignIn");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const subscribeToConsultations = () => {
    const docRef = doc(db, "consultations", "allConsultations");
    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          const consultationsArray = Object.entries(data).map(
            ([id, consultation]) => ({
              id,
              ...consultation,
            })
          );
          // Sort consultations by date, from most recent to oldest
          const sortedConsultations = consultationsArray.sort(
            (a, b) => b.createdAt.toDate() - a.createdAt.toDate()
          );
          setConsultations(sortedConsultations);
        }
      },
      (error) => {
        console.error("Error fetching consultations:", error);
      }
    );

    return unsubscribe;
  };

  useEffect(() => {
    if (user) {
      const unsubscribe = subscribeToConsultations();
      return () => unsubscribe();
    }
  }, [user]);

  if (!user) {
    return null; // or a loading indicator
  }

  return (
    <section className="tablePage">
      <h2>Consultation</h2>
      <div className="tableres">
        <Table bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Message</th>
              <th>Media</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {consultations.map((consultation, index) => (
              <tr key={consultation.id}>
                <td>{index + 1}</td>
                <td>{consultation.name}</td>
                <td>{consultation.email}</td>
                <td>{consultation.message}</td>
                <td>
                  {consultation.mediaUrl ? (
                    <a
                      href={consultation.mediaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                  ) : (
                    "No"
                  )}
                </td>
                <td>{consultation.createdAt.toDate().toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </section>
  );
}

export default TablePage;
