import React, { useState } from "react";
import Footer from "../../components/Footer";
import Booking from "../../components/Booking";

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question:
        "What timeshare exit services does Consumer Protection Networks offer?",
      answer:
        "At Consumer Protection Networks, we utilize our network of legal and professional experts to release clients from their timeshare properties by legally transferring the timeshare title out of their names.",
    },
    {
      question:
        "What if I still owe on the mortgage or I am behind on maintenance fees?",
      answer:
        "At Consumer Protection Networks, we have a team of legal experts equipped to assist clients cancel their timeshare, even if there is a mortgage on the timeshare or maintenance fees are owed.",
    },
    {
      question: "How much does it cost to exit a timeshare?",
      answer:
        "Every client's situation is unique, and we provide a free consultation to review your situation. We will provide a cost tailored to your needs. Please contact us to review your timeshare exit options or for more information.",
    },
    {
      question: "How do I stay updated during the timeshare exit process?",
      answer:
        "We take pride in having exceptional customer service and offer numerous methods to stay up to date with your timeshare cancellation. You can call our toll-free line or schedule a time to speak with an expert and receive updates with our Release Department.",
    },
    {
      question: "How do I start the process of exiting my timeshare contract?",
      answer:
        "We recommend contacting us for a free consultation and asking about available exit programs. Follow up with written correspondence and explain why you want to exit your timeshare, including any misrepresentations or high-pressure sales tactics you experienced.",
    },
    {
      question: "How long can it take to exit my timeshare contract?",
      answer:
        "The time it takes to exit a timeshare contract varies based on several factors, including the specifics of your timeshare agreement and the legal processes involved. We guarantee the process will take no longer than 18 months.",
    },
    {
      question: "What if I have already stopped paying on my timeshare?",
      answer:
        "If you've already stopped paying on your timeshare, it's important to address the situation as soon as possible to minimize potential financial repercussions. We specialize in providing solutions for properties with defaulted payments.",
    },
    {
      question: "Can the exit process affect my credit?",
      answer:
        "Yes, it can, depending on how you exit. If you stop paying on your timeshare, your credit can be negatively affected. We can recommend a credit repair agency to help address any issues with your credit report.",
    },
  ];
  return (
    <>
      <section id="FAQ">
        <header className="faq-header">
          Frequently Asked Questions (FAQ’s)
        </header>
        <div className="accordion wow animate__animated animate__zoomIn">
          {faqs.map((faq, index) => (
            <div key={index} className="accordion-item">
              <input
                type="checkbox"
                id={`accordion${index}`}
                checked={openIndex === index}
                onChange={() => toggleAccordion(index)}
              />
              <label
                htmlFor={`accordion${index}`}
                className="accordion-item-title"
              >
                <span className="icon"></span>
                {faq.question}
              </label>
              {openIndex === index && (
                <div className="accordion-item-desc">
                  <strong>{faq.answer}</strong>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
      <Booking />

      <Footer />
    </>
  );
};

export default FAQ;
