import React, { useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../services/firebase";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";

const Booking = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    mediaUrl: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const uuid = uuidv4();
    const docName = "allConsultations";

    let mediaUrl = "";
    if (selectedFile) {
      const storageRef = ref(storage, `/media/${uuid}_${selectedFile.name}`);
      try {
        await uploadBytes(storageRef, selectedFile);
        mediaUrl = await getDownloadURL(storageRef);
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
    }

    const updatedFormData = {
      ...formData,
      mediaUrl,
      createdAt: new Date(),
    };

    try {
      await setDoc(
        doc(db, "consultations", docName),
        {
          [uuid]: updatedFormData,
        },
        { merge: true }
      );

      Swal.fire({
        icon: "success",
        title: "Consultation Sent Successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      setFormData({
        name: "",
        email: "",
        message: "",
        mediaUrl: "",
      });
      setSelectedFile(null);
      document.getElementById("media").value = "";
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something Went Wrong",
        showConfirmButton: false,
        timer: 1500,
      });
      console.error("Error submitting consultation: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div id="booking" style={{ padding: "20px" }}>
      <div className="form">
        <div className="form-section">
          <h1 className="form-heading">REQUEST A FREE CONSULTATION NOW!</h1>
        </div>

        <div className="form-content">
          <form id="myform" className="form-animate" onSubmit={handleSubmit}>
            <label htmlFor="name">Full Name</label>
            <input
              id="name"
              name="name"
              type="text"
              placeholder="Enter your full name"
              className="form-control"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter your email"
              className="form-control"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Enter your message"
              className="form-control"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <label htmlFor="media">Attach Files</label>
            <input
              type="file"
              id="media"
              placeholder="Attach Your File"
              accept="image/*, video/*, audio/*"
              style={{ display: "block" }}
              onChange={handleFileChange}
            />
            <div style={{ textAlign: "center" }}>
              <button
                id="submit"
                type="submit"
                className="form-submit"
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Send Now"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Booking;
