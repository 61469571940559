import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

function Navbarr() {


  const closeNavbar = () => {
    const navBar = document.querySelector(".navbar-collapse");
    if (navBar.classList.contains("show")) {
      navBar.classList.remove("show");
    }
  };

  return (
    <Navbar
      className={`navbar navbar-expand-lg navbar-light bg-brown fixed`}
      expand="lg"
    >
      <Navbar.Brand href="/">
        <img src="images/logo.png" alt="Your Logo" width="100" height="auto" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarSupportedContent" />
      <Navbar.Collapse id="navbarSupportedContent" className="ml-auto">
        <Nav className="ml-auto">
          <Nav.Item className="nav-item">
            <NavLink className="nav-link" to="/" onClick={closeNavbar}>
              Home
            </NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink className="nav-link" to="/About" onClick={closeNavbar}>
              About Us
            </NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink className="nav-link" to="/Process" onClick={closeNavbar}>
              Process
            </NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink className="nav-link" to="/Reviews" onClick={closeNavbar}>
              Reviews
            </NavLink>
          </Nav.Item>
          <Nav.Item className="nav-item">
            <NavLink className="nav-link" to="/FAQ" onClick={closeNavbar}>
              FAQ
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavHashLink smooth className="btn btn-white12 my-2 my-sm-0" to="#booking">
              Contact Us
            </NavHashLink>

          </Nav.Item>

        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navbarr;
